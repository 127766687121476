<template>
  <div>
    <song-list :songs="songs" v-on:loadSongs="loadSongs" :title="title" prefix="khi-nhac"/>
  </div>
</template>


<script>
import ApiService from "../../core/services/api.service";
import SongList from "../song/SongList";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: "InstrumentalList",
  components: {
    SongList
  },
  data() {
    return {
      title: "Khí nhạc",
      songs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadSongs(options) {
      let query = `query($page: Int, $orderBy: [OrderByClause!], $vovHQ: Boolean) {
        instrumentals(first: 20, page: $page, orderBy: $orderBy, vovHQ: $vovHQ) {
          data {
            id
            slug
            title
            views
            downloads
            thumbnail {
              url
            }
            file {
              is_hq
            }
            sheet {
              year
              composers(first: 20) {
                data {
                  id
                  slug
                  title
                }
              }
            }
            artists(first: 20) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, options)
          .then(({data}) => {
            if (data.data && data.data.instrumentals) {
              this.songs = data.data.instrumentals;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    changePageTitle(this.title);
  }
}
</script>